<script>
import { Doughnut, mixins  } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Doughnut,
  data: () => ({
    mydata: [],
    Departments: []
  }),
  mixins: [reactiveProp],
  props: {
    selectedmonth:{
        type: String,
        default: 'Jan'
    },
    options: {
      type: Object,
      default: null
    }
  },
  mounted () {
    let self = this
    console.log(self.selectedmonth,'selectedmonth in report');
    // this.renderChart(this.chartdata, this.options)

    if(this.chartData !== null ){
      console.log(this.chartData,'chart');
      self.renderChart(self.chartData, self.options)
    }


    this.$binding("Departments", this.$db.collection('Departments'))
    .then((dept) => {
      console.log(this.Departments,'dept');
      console.log(dept,'dept');
    })

    this.$binding("mydata", this.$db.collection('ORMPCoverage').orderBy('CreatedAt','desc'))
    .then((reports) => {
      
      if(reports){
        console.log(reports,'in component') // => __ob__: Observer

      let month = self.$moment().format('MMM YYYY')
      console.log(month,'selected month');

      let ForUpdate = reports.filter(a=>{
          return this.$moment(a.startDate).format('MMM YYYY') == month && a.isApproved == false
      })

      let Updated = reports.filter(a=>{
          return this.$moment(a.startDate).format('MMM YYYY') == month && a.isApproved == true
      })

      let remove = self.Departments.filter(a=>{
        return a.departmentName !== 'RISK MANAGEMENT TEAM'
      })

      let ForUpdateMap = remove.filter(a=>{
        return this.$lodash.findIndex(Updated,x=>{
          return x.Department == a.departmentName
        }) == -1
      })

      console.log(ForUpdateMap,'ForUpdateMap');

      console.log(Updated.length,ForUpdate.length);

      let chartdata = {
        labels: ['Updated', 'For Update'],
        datasets: [
          {
            backgroundColor: ['#f87979','#FFD600'],
            data: [Updated.length,ForUpdateMap.length]
          },
        ]
      }

        self.renderChart(chartdata, self.options)
      }
    }).catch((err) => {
      console.log(err,'error bar graph');
      self.renderChart(self.mydata, self.options)
    })
  }
}
</script>