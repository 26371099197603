<script>
import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  data: () => ({
    mydata: [],
  }),

  props: {

    options: {
      type: Object,
      default: null
    }
  },
  mounted () {
    let self = this
    this.$binding("mydata", this.$db.collection('ReportedRisk').orderBy('timestamp','desc'))
    .then((risks) => {
      // console.log(risks,'in component') // => __ob__: Observer
      if(risks){

        let month = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
        let data = []
        let colors = []
        let forFetch = []
        month.forEach(a=>{
          let count = self.$lodash.filter(risks, x=>{
            return self.$moment(x.timestamp.toDate()).format("MMM") == a && self.$moment(x.timestamp.toDate()).format("YYYY") == self.$moment().format("YYYY")
          })
          data.push(count.length)
          colors.push('')
          forFetch.push(count)
        })

        let obj = {
          labels: month,
          datasets: [
            {
              label: 'Risk Transpired',
              backgroundColor: colors,
              data: data
            },
          ]
        }

        self.renderChart(obj, self.options)
              
      }
    }).catch((err) => {
      console.log(err,'error bar graph');
      self.renderChart(self.mydata, self.options)
    })
  }
}
</script>