<script>
import { Bar, mixins  } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Bar,
  data: () => ({
    mydata: []
  }),
  mixins: [reactiveProp],
  props: {
    selectedmonth:{
        type: String,
        default: 'Jan'
    },
    options: {
      type: Object,
      default: null
    }
  },
  mounted () {
    let self = this
    console.log(self.selectedmonth,'selectedmonth in report');

    if(this.chartData !== null){
      console.log(this.chartData,'chart');
      self.renderChart(self.chartData, self.options)
    }


    this.$binding("Departments", this.$db.collection('Departments'))
    .then((dept) => {
      console.log(this.Departments,'dept');
      console.log(dept,'dept');
    })




    this.$binding("mydata", this.$db.collection('RAEME_REPORTS').orderBy('timestamp','desc'))
    .then((reports) => {
      console.log(reports,'in component') // => __ob__: Observer
      if(reports){
        let self = this
        if(self.Departments){
          let depts = self.Departments.map(a=>{
            return a.departmentName
          })

          console.log(depts,'deptsmap');

          let data = []
          let colors = []

          depts.forEach(a=>{
            let filter = self.$lodash.filter(reports,x=>{
              return self.$moment(x.timestamp.toDate()).format("MMM") == self.selectedmonth 
              && self.$moment(x.timestamp.toDate()).format("YYYY") == self.$moment().format("YYYY") 
              && x.Department == a
            })

            if(filter.length == 0){
               data.push(0)
               colors.push('')
            } else {
              let max = this.$lodash.max(filter.map(x=>{
                if(x.Last6Months !== ''){
                  return x.Last6Months
                }
              }))

              data.push(max !== undefined ? parseFloat(max) : 0)
              colors.push('')
            }

          })


        let obj = {
          labels: depts,
          datasets: [
            {
              label: 'Financial Impact',
              backgroundColor: colors,
              data: data
            },
          ]
        }

        self.renderChart(obj, self.options)
        }


              
      }
    }).catch((err) => {
      console.log(err,'error bar graph');
      self.renderChart(self.mydata, self.options)
    })
  }
}
</script>