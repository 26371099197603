<template>
  <div class="pa-12 pt-16">
    <div class="text-h4 font-weight-bold grey--text text--darken-2">{{returnDay}} {{returnDisplayName}},</div>
    <div class="mt-6">
      <v-banner two-line color="white" class="grey--text text--darken-2" v-show="returnLengthNotSeen > 0 && showBanner == true">
        <v-avatar
          slot="icon"
          color="primary"
          size="40"
        >
          <v-icon
            color="white"
          >
            mdi-alert
          </v-icon>
        </v-avatar>

       There are {{returnLengthNotSeen}} risk reported today. Click Check Report for more details.

        <template v-slot:actions>
          <v-btn
            text
            color="grey"
            @click="showBanner = false"
          >
            Dismiss
          </v-btn>
          <v-btn
            color="primary"
            @click="$router.push('/reportedrisk')"
          >
            Check Report
          </v-btn>
        </template>
      </v-banner>
      <v-banner two-line color="white" class="grey--text text--darken-2" v-show="returnLengthNotSeenAlert > 0 && showAlert == true">
        <v-avatar
          slot="icon"
          color="primary"
          size="40"
        >
          <v-icon
            color="white"
          >
            mdi-alert
          </v-icon>
        </v-avatar>

       There are {{returnLengthNotSeenAlert}} risk reported in your department. Please update your <b>ORMP</b>'s. Click Check Report for more details.

        <template v-slot:actions>
          <v-btn
            text
            color="grey"
            @click="showAlert = false"
          >
            Dismiss
          </v-btn>
          <v-btn
            color="primary"
            @click="$router.push('/riskalerts')"
          >
            Check Report
          </v-btn>
        </template>
      </v-banner>
      <v-row class="mt-8 px-3">


        <v-card class="col-4">
            <div class="text-h6 ml-2 grey--text text--darken-2 mb-4"> 
              ORMP Update 
              <span class="grey--text font-weight-bold">( {{monthSelectORMPUpdate[monthSelectedORMPUpdate]}} {{$moment().format('YYYY')}} )</span>
            <v-menu
              bottom
              left
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="grey darken-3"
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  class="float-right mr-2"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-card>
                <div class="pa-4 font-weight-bold">Change Chart Data</div>
                <v-divider></v-divider>
                <v-list-item-group
                  v-model="monthSelectedORMPUpdate"
                  color="primary"
                >
                  <v-list-item
                    v-for="(item, index) in monthSelectORMPUpdate"
                    :key="index"
                    @click="pushToPieChart(`${item} ${$moment().format('YYYY')}`)"
                  >
                    <v-list-item-title>{{ item }}  {{$moment().format('YYYY')}}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>             
              </v-card>

            </v-menu>

            </div>
            <PieChart :chart-data="chartDataPie" :options="options" :selectedmonth="monthSelectORMPUpdate[monthSelectedORMPUpdate]"/>
          <div class="text-center">
            <v-btn color="grey  darken-3" dark class="mx-auto mt-4" @click="$router.push(`/ormpupdatelist?q=${monthSelectORMPUpdate[monthSelectedORMPUpdate]} ${$moment().format('YYYY')}`)">view list</v-btn>
          </div>
          
        </v-card>

        <v-col class="col-4">
            <div class="text-h6 ml-4 grey--text text--darken-2"> 
              {{menuListItems[menuList]}} 
              <v-btn color="grey" outlined x-small class="ml-1" @click="viewListPage(menuListItems[menuList])">VIEW LIST</v-btn>
            <v-menu
              bottom
              left
              offset-y

            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="grey darken-3"
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  class="float-right mr-4"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-card>
                <div class="pa-4 font-weight-bold">Change List Data</div>
                <v-divider></v-divider>
                <v-list-item-group
                  v-model="menuList"
                  color="primary"
                >
                  <v-list-item
                    v-for="(item, index) in menuListItems"
                    :key="index"
                  >
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>             
              </v-card>

            </v-menu>

            </div>
            <v-card v-for="(logs,idx) in returnList" :key="logs['.key']" class="my-4">
              <v-list-item v-if="menuList == 0">
                <v-list-item-avatar>
                  <v-img :src="logs.photoURL"></v-img>
                </v-list-item-avatar>
               
                <v-list-item-content>
                  <v-list-item-title v-html="logs.action" class="grey--text text--darken-2"></v-list-item-title>
                  <v-list-item-subtitle v-html="logs.email" class="grey--text text--darken-1"></v-list-item-subtitle>
                  <v-list-item-subtitle v-html="$moment(logs.timestamp.toDate()).format('LLLL')" class="grey--text text--darken-1 text-caption mt-2"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-else>
                <v-list-item-avatar>
                  {{idx+1}}
                </v-list-item-avatar>
               
                <v-list-item-content>
                  <v-list-item-title v-html="logs.RiskName" class="grey--text text--darken-2"></v-list-item-title>
      
                  <v-list-item-subtitle v-html="$moment(logs.timestamp.toDate()).format('LLLL')" class="grey--text text--darken-1 text-caption mt-2"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>                  
            </v-card>
     
        </v-col>

        <v-card class="col-4">
            <div class="text-h6 ml-2 grey--text text--darken-2 mb-4"> 
              KRI Gauge
              <span class="grey--text font-weight-bold">( {{gaugeItems[gaugeSelected]}} )</span>
            <v-menu
              bottom
              left
              offset-y

            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="grey darken-3"
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  class="float-right mr-2"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-card>
                <div class="pa-4 font-weight-bold">Change Chart Data</div>
                <v-divider></v-divider>
                <v-list-item-group
                  v-model="gaugeSelected"
                  color="primary"
                >
                  <v-list-item
                    v-for="(item, index) in gaugeItems"
                    :key="index"
                  >
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>             
              </v-card>

            </v-menu>

            </div>
          <!-- <BarChart :chart-data="chartdata" :options="options"/> -->
          <VueSvgGauge
            :start-angle="-110"
            :end-angle="110"
            :value="returnValueGauge"
            :separator-step="10"
            :min="0"
            :max="100"
            :gauge-color="[{ offset: 0, color: '#E6BF00'}, { offset: 100, color: '#FF4D4D'}]"
            :scale-interval="0.1"
          />
        </v-card>
      </v-row>
      <v-row class="mt-8 px-3">
        <v-card class="col">
            <div class="text-h6 ml-2 grey--text text--darken-2 mb-4 row"> 
              {{barItems[barSelected]}}
                <v-spacer></v-spacer>
                
                <v-menu
                  bottom
                  left
                  offset-y
                  v-show="barSelected == 1"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="grey darken-3"
                      dark
                      v-bind="attrs"
                      v-show="barSelected == 1"
                      class="mr-4 mt-2"
                      v-on="on">show report
                    </v-btn>
                  </template>
                  <v-card>
                    <div class="pa-4 font-weight-bold">Select Department</div>
                    <v-divider></v-divider>
                    <v-list-item-group
                      v-model="deptSelectedFinancial"
                      color="primary"
                    >
                      <v-list-item
                        v-for="(item, index) in returnDepts"
                        :key="index"
                        @click="redirectToReport(item)"
                      >
                        <v-list-item-title>{{ item }}</v-list-item-title>
                      </v-list-item>

                    </v-list-item-group>  
                  </v-card>
                </v-menu>
              
                <v-select
                :items="monthSelectFinancial"
                v-model="monthSelectedFinancial"
                class="mr-2 mt-2 float-right col-2"
                outlined
                label="Select Month"
                dense
                hide-details
                v-show="barSelected == 1"
                @change="pushDataFinancial()"
              ></v-select>  

              <div class="col-1">
            <v-menu
              bottom
              left
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="grey darken-3"
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  style="width:5px;height:25px"
                  class="float-right mr-2"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-card>
                <div class="pa-4 font-weight-bold">Change Chart Data</div>
                <v-divider></v-divider>
                <v-list-item-group
                  v-model="barSelected"
                  color="primary"
                >
                  <v-list-item
                    v-for="(item, index) in barItems"
                    :key="index"
                    @click="index == 1 ? changeValueOfSelect(): ''"
                  >
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item>

                </v-list-item-group>  
                <v-list v-show="barSelected == 0">
                  <v-list-item>
                    <v-list-item-action>
                      <v-switch
                        v-model="barRawData"
                      ></v-switch>                      
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>Show Raw Data</v-list-item-title>
                      <v-list-item-subtitle>This will display data that is represented in the graph.</v-list-item-subtitle>
                    </v-list-item-content>                    
                  </v-list-item>
                </v-list>
              </v-card>

              </v-menu>
              </div>
            </div>
          <!-- <BarChart 
            :chart-data="fillDataBar" 
            :options="{responsive: true, maintainAspectRatio: false}" 
            v-if="loaded"
          /> -->
          <div v-show="!barRawData">
          <BarChartReportedRisk 
          v-if="barSelected == 0"
            :options="{responsive: true, maintainAspectRatio: false}" 
          />
          <BarChartHighestFinancial 
          v-else
            :chart-data="fillDataBar"
            :selectedmonth="monthSelectedFinancial"
            :options="{responsive: true, maintainAspectRatio: false}" 
          />  
          </div>
          <div v-show="barRawData && barSelected == 0">
            <div>
              <v-row>
                <v-col>
                  <v-expansion-panels focusable>
                    <v-expansion-panel
                      v-for="item in returnReportRiskData.slice(0,6)"
                      :key="item.month"
                    >
                      <v-expansion-panel-header>{{item.month}}</v-expansion-panel-header>
                      <v-expansion-panel-content v-if="item.data.length > 0">
                        <div v-for="data in item.data" :key="data['.key']" class="mt-4">
                          {{data.RiskName}} - <span class="text-caption accent--text">Reported Last: {{$moment(data.timestamp.toDate()).format('LL')}}</span>
                        </div>
                      </v-expansion-panel-content>
                      <v-expansion-panel-content v-else>
                        <div class="my-4 text-center">
                          There is no data to be shown.
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>                  
                </v-col>
                <v-col>
                  <v-expansion-panels focusable>
                    <v-expansion-panel
                      v-for="item in returnReportRiskData.slice(6,12)"
                      :key="item.month"
                    >
                      <v-expansion-panel-header>{{item.month}}</v-expansion-panel-header>
                      <v-expansion-panel-content v-if="item.data.length > 0">
                        <div v-for="data in item.data" :key="data['.key']" class="mt-4">
                          {{data.RiskName}} - <span class="text-caption accent--text">Reported Last: {{$moment(data.timestamp.toDate()).format('LL')}}</span>
                        </div>
                      </v-expansion-panel-content>
                      <v-expansion-panel-content v-else>
                        <div class="my-4 text-center">
                          There is no data to be shown.
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>    
                </v-col>
              </v-row>
            </div>  
          </div>

        </v-card>
      </v-row>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import BarChart from '../components/BarChart.vue' 
import BarChartReportedRisk from '../components/BarChartReportedRisk.vue'
import BarChartHighestFinancial from '../components/BarChartHighestFinancial.vue'
import PieChart from '../components/PieChart.vue'
import { VueSvgGauge } from 'vue-svg-gauge'

export default {
  name: 'Dashboard',
  components: {
    // BarChart,
    BarChartHighestFinancial,
    BarChartReportedRisk,
    PieChart,
    VueSvgGauge,
  },
  data: () => ({
    showAlert: true,
    barRawData:false,
    chartDataPie: null,
    monthSelectedORMPUpdate: 0,
    monthSelectORMPUpdate: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],   
    monthSelectedFinancial: 'Jan',
    monthSelectFinancial: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
    barSelected: 0,
    barItems: ['Transpired/Reported Risk per Month','Highest Financial Impact per Department'],
    chartSelected: 0,
    chartItems: ['Mar-Apr','Sep-Oct'],
    gaugeSelected: 0,
    gaugeItems: ['Current Quarter','Last Quarter'],
    menuList: 0,
    menuListItems: ['Recent Update','Top 5 Risk Transpired'],
    showBanner: true,
    chartdata: {
      labels: ['Updated', 'For Update'],
      datasets: [
        {
          backgroundColor: ['#f87979','#FFD600'],
          data: [40, 20]
        },
      ]
    },
    loaded: false,
    fillDataBar: null,
    dataPassedNew: null,
    barchartdata: {
      labels: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],
      datasets: [
        {
          label: 'Risk Transpired',
          backgroundColor: ['#f87979'],
          data: [40, 20, 0,10]
        },
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: true
    },
      items: [
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          title: 'Brunch this weekend?',
          subtitle: "<span class='text--primary'>Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?",
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
          title: 'Summer BBQ <span class="grey--text text--lighten-1">4</span>',
          subtitle: "<span class='text--primary'>to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.",
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
          title: 'Oui oui',
          subtitle: "<span class='text--primary'>Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?",
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
          title: 'Birthday gift',
          subtitle: "<span class='text--primary'>Trevor Hansen</span> &mdash; Have any ideas about what we should get Heidi for her birthday?",
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/5.jpg',
          title: 'Recipe to try',
          subtitle: "<span class='text--primary'>Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.",
        },
      ],
      fetchData: null,
      withData: false,
      deptSelectedFinancial: 0,
      deptSelectionInReport: []
  }),
  created(){
     this.monthSelectedORMPUpdate = this.$lodash.findIndex(this.monthSelectORMPUpdate,a=>{
       return a == this.$moment().format('MMM')
     })
  },
  mounted(){
    
  },
  firestore(){
    return {
      KRICoverage: this.$db.collection('KRICoverage').orderBy('CreatedAt','desc'),
      KRI_REPORTS: this.$db.collection('KRI_REPORTS').orderBy('CreatedAt','desc'),
      RAEME_REPORTS: this.$db.collection('RAEME_REPORTS').orderBy('timestamp','desc'),
      Departments: this.$db.collection('Departments'),
      ReportedRisk: this.$db.collection('ReportedRisk').orderBy('timestamp','desc'),
      RiskAlerts: this.$db.collection('RiskAlerts').orderBy('timestamp','desc'),
      ORMPCoverage: this.$db.collection('ORMPCoverage').orderBy('CreatedAt','desc'),
      ActivityLogs: this.$db.collection('ActivityLogs').where('module','in',['ORMP','KRI','RAEME','PRMP']).orderBy('timestamp','desc').limit(15),
    }
  },
  computed:{
    returnReportRiskData(){
      let month = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
      let data = []
      let reports = this.ReportedRisk
      month.forEach(a=>{
        let count = this.$lodash.filter(reports, x=>{
          return this.$moment(x.timestamp.toDate()).format("MMM") == a && this.$moment(x.timestamp.toDate()).format("YYYY") == this.$moment().format("YYYY")
        }) 
        data.push({month: a,data: count})      
      })
      return data
    },
    returnTodayMonth(){
      return this.$moment().format('MMM')
    },
    returnList(){
      if(this.menuList == 0){
        return this.returnLogs.slice(0,4)
      } else {
        return this.returnTranspiredRisk.slice(0,5)
      }
    },
    returnTranspiredRisk(){
      let risks = this.$lodash.uniqBy(this.ReportedRisk,'RiskName')
      return risks
    },
    returnLogs(){
      let user = this.$store.getters['useraccount/isAuthenticated']
      // let logs = this.ActivityLogs.filter(a=>{
      //   return a.module == 'ORMP'
      // })

      // console.log(this.ActivityLogs,'Act:O');


      if(user.department !== 'RISK MANAGEMENT TEAM'){
        return this.ActivityLogs.filter(a=>{
          return a.department == user.department
        })
      }

      return this.ActivityLogs
    },
    returnLengthNotSeen(){
      let userDept = this.$store.getters['useraccount/isAuthenticated'].department
      if(userDept !== 'RISK MANAGEMENT TEAM') return 0
      return this.ReportedRisk.filter(a=>{
        return a.isVerified == undefined
      }).length
    },
    returnLengthNotSeenAlert(){
      let userDept = this.$store.getters['useraccount/isAuthenticated'].department
      if(userDept == 'RISK MANAGEMENT TEAM') return []
      return this.RiskAlerts.filter(a=>{
        return a.isVerified == undefined && this.$lodash.includes(a.Department,userDept)
      }).length
    },
    returnDay(){
      let time = new Date()
      let moment = this.$moment(time).format('HH')
      if(moment < 12) return 'Good Morning'
      if(moment == 12) return 'Good Noon'
      if(moment >= 13 && moment < 16) return 'Good Afternoon'
      if(moment >= 16) return 'Good Evening'
      return ''
    },
    returnDisplayName(){
      let name = this.$store.getters['useraccount/isAuthenticated']
      let split = name.displayName.split(' ')
      return split[0]
    },
    returnORMPUpdateCoverage(){
      return ''
      // if Mar-Apr same and after but before Sep - Oct

      // if Sep-Oct same and after till end of month
    },
    returnKRICoverageData(){
      let KRICoverage = this.KRICoverage.filter(a=>{
        return this.$moment(a.startDate).format('YYYY') == this.$moment().format('YYYY')
      })[0]

      let quarters = [
        {month: 'Jan',q: 1},
        {month: 'Feb',q: 1},
        {month: 'Mar',q: 1},
        {month: 'Apr',q: 2},
        {month: 'May',q: 2},
        {month: 'Jun',q: 2},
        {month: 'Jul',q: 3},
        {month: 'Aug',q: 3},
        {month: 'Sep',q: 3},
        {month: 'Oct',q: 4},
        {month: 'Nov',q: 4},
        {month: 'Dec',q: 4},
      ]


      let todayQuarter = quarters.filter(a=>{
        return this.$moment().format('MMM') == a.month
      })[0]
      
      let KRI_REPORTS = this.KRI_REPORTS.filter(a=>{
        return a.coverageId == KRICoverage['.key']
      })

      console.log(KRICoverage,'KRICoverage');
      console.log(todayQuarter,'todayQuarter');
      console.log(KRI_REPORTS,'KRI_REPORTS');

      return {
        reports: KRI_REPORTS,
        quarter: todayQuarter
      }
    },
    returnLastQuarter(){
      let { reports, quarter } = this.returnKRICoverageData

      let lastQuarter = quarter.q - 1

      if(lastQuarter == 0){
        return 0
      }

      let lastQuarterScores = reports.map(a=>{
        return  parseFloat(a[`KRI_TrendQ${lastQuarter}`])
      })

      // console.log(this.$lodash.sum(lastQuarterScores),'lastQuarterScores');
      return this.$lodash.sum(lastQuarterScores) / lastQuarterScores.length
    },
    returnCurrentQuarter(){
      let { reports, quarter } = this.returnKRICoverageData

      let todaysQuarter = quarter.q

      let todaysQuarterScores = reports.map(a=>{
        return parseFloat(a[`KRI_TrendQ${todaysQuarter}`])
      })

      // console.log(this.$lodash.sum(todaysQuarterScores),'todaysQuarterScores');

      return this.$lodash.sum(todaysQuarterScores) / todaysQuarterScores.length
    },
    returnValueGauge(){
      let current = parseFloat(this.returnCurrentQuarter.toFixed(2))
      let last = parseFloat(this.returnLastQuarter.toFixed(2))
      let sum = current + last
      let vs = parseFloat(sum/current)

      return 100 / parseFloat(vs.toFixed(2))
    },
    returnDepts(){
      return this.Departments.map(a=>{
        return a.departmentName
      })
    }
  },
  methods:{
    viewListPage(type){
      console.log(type,'type');
      this.$router.push(`/dashboardlist/${type}`)
    },
    redirectToReport(dept){
      let date = this.$moment().format('YYYY')
      let moment = this.$moment(`${this.monthSelectedFinancial} ${date}`).format('MMMM YYYY')
      this.$router.push(`/RAEME?q=${moment}/${dept}`)
    },
    changeValueOfSelect(){
      this.monthSelectedFinancial = this.$moment().format('MMM')
      this.barRawData = false
    },
    pushDataFinancial(){
        let self = this

        let reports = self.RAEME_REPORTS
          let depts = self.Departments.map(a=>{
            return a.departmentName
          })



          let data = []
          let colors = []

          depts.forEach(a=>{
            let filter = self.$lodash.filter(reports,x=>{
              return self.$moment(x.timestamp.toDate()).format("MMM") == self.monthSelectedFinancial 
              && self.$moment(x.timestamp.toDate()).format("YYYY") == self.$moment().format("YYYY") 
              && x.Department == a
            })

            if(filter.length == 0){
               data.push(0)
               colors.push('')
            } else {
              let max = this.$lodash.max(filter.map(x=>{
                if(x.Last6Months !== ''){
                  return x.Last6Months
                }
              }))

              data.push(max !== undefined ? parseFloat(max) : 0)
              colors.push('')
            }

          })


        let obj = {
          labels: depts,
          datasets: [
            {
              label: 'Financial Impact',
              backgroundColor: colors,
              data: data
            },
          ]
        }

        self.withData = this.$lodash.max(data) == 0 ? false : true
        self.deptSelectionInReport = depts
        console.log(self.deptSelectionInReport,'deptSelectionInReport');
        self.fillDataBar = obj

        console.log('this code is activated', );
    },
    pushToPieChart(month){
      let self = this
      console.log(month,'selected month');

      let ForUpdate = this.ORMPCoverage.filter(a=>{
          return this.$moment(a.startDate).format('MMM YYYY') == month && a.isApproved == false
      })

      let Updated = this.ORMPCoverage.filter(a=>{
          return this.$moment(a.startDate).format('MMM YYYY') == month && a.isApproved == true
      })

      let remove = self.Departments.filter(a=>{
        return a.departmentName !== 'RISK MANAGEMENT TEAM'
      })


      let ForUpdateMap = remove.filter(a=>{
        return this.$lodash.findIndex(Updated,x=>{
          return x.Department == a.departmentName
        }) == -1
      })

      console.log(ForUpdateMap,'ForUpdateMap');

      console.log(Updated.length,ForUpdate.length);

      let chartdata = {
        labels: ['Updated', 'For Update'],
        datasets: [
          {
            backgroundColor: ['#f87979','#FFD600'],
            data: [Updated.length,ForUpdateMap.length]
          },
        ]
      } 

      console.log(chartdata,'chartdata');
      
      self.chartDataPie = chartdata
    }
  }

}
</script>
